import React, { useContext, useEffect, useState } from "react";
import { MODAL_STYLES, OVERLAY_STYLES } from "./CalendarPeriodsAdd_ModalStyle";
import { RESPONSIVE_MODAL_STYLES } from "../../../Modal_Styles/ResponsiveModalStyle";
import "./CalendarPeriodsCrud.css";
import { GetPrompts } from "../../../Prompts/PromptsComponent";
import DatePickerPopUp from "../../../DatePicker/DatePickerPopUp";
import moment from "moment";
import { CreatePlanPeriod } from "./CreatePlanPeriod";
import { UpdatePlanPeriod } from "./UpdatePlanPeriod";
import { DeletePlanPeriod } from "./DeletePlanPeriod";
import YesNoConfirm from "../../../ModalConfirm/YesNoConfirm";
import { Context } from "../../../../Context/Contexts";
export default function CalendarPeriodsCrud({
	open,
	close,
	getPeriodData,
	periodInEdition,
}) {
	const { setShowIsloading } = useContext(Context);
	const { setShowDonecomponent } = useContext(Context);

	const CloseClickHandler = () => {
		close();
	};
	const [showDateDateTimePicker, setShowDateDateTimePicker] = useState(false);
	const [yesNoDlgOpen, setYesNoDlgOpen] = useState(false);

	const [requestedDate, setRequestedDate] = useState(new Date());
	const [startFromDate, setStartFromDate] = useState(new Date());
	const [numberOfWeeks, setNumberOfWeeks] = useState(12);
	const [periodTitle, setPeriodTitle] = useState("");
	const [isDefault, setIsDefault] = useState(false);

	const DateEditionHandler = (e) => {
		setStartFromDate(moment(e).format("YYYY-MM-DDTHH:mm:ss"));
		setRequestedDate(moment(e).format("YYYY-MM-DDTHH:mm:ss"));
	};

	const CreatePeriod = () => {
		setShowIsloading(true);
		CreatePlanPeriod({
			startFromDate: startFromDate,
			numberOfWeeks: numberOfWeeks,
			periodTitle: periodTitle,
		}).then((response) => {
			resetVariables();
			getPeriodData();
			setShowIsloading(false);
			setShowDonecomponent(true);
			close();
		});
	};
	const UpdatePeriod = () => {
		setShowIsloading(true);
		var periodId = periodInEdition.planCalendarPeriod.calendarPeriodId;
		UpdatePlanPeriod({
			periodId: periodId,
			startFromDate: startFromDate,
			numberOfWeeks: numberOfWeeks,
			periodTitle: periodTitle,
		}).then((response) => {
			resetVariables();
			getPeriodData();
			setShowIsloading(false);
			setShowDonecomponent(true);
			close();
		});
	};
	const DeletePeriod = () => {
		setShowIsloading(true);
		var periodId = periodInEdition.planCalendarPeriod.calendarPeriodId;
		DeletePlanPeriod({
			periodId: periodId,
		}).then((response) => {
			resetVariables();
			getPeriodData();
			setShowIsloading(false);
			setShowDonecomponent(true);
			close();
		});
	};

	const resetVariables = () => {
		setYesNoDlgOpen(false);
		if (periodInEdition !== undefined) {
			setRequestedDate(periodInEdition.planCalendarPeriod.startFromDate);
			setStartFromDate(periodInEdition.planCalendarPeriod.startFromDate);
			setNumberOfWeeks(periodInEdition.planCalendarPeriod.numberOfWeeks);
			setPeriodTitle(periodInEdition.planCalendarPeriod.periodTitle);
			setIsDefault(periodInEdition.isDefault);
		}
	};
	useEffect(() => {
		if (open === true) {
			resetVariables();
		}
	}, [open]);

	if (!open) return null;

	return (
		<>
			<div style={OVERLAY_STYLES} onClick={CloseClickHandler}></div>
			<div
				className="calendar-period-add-container"
				style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES)}
			>
				<div
					className="calendar-period-add-close-btn"
					onClick={CloseClickHandler}
				>
					X
				</div>

				<div className="calendar-period-add-row">
					<div className="calendar-period-add-label">
						{GetPrompts("Starts from")}:
					</div>
					<div
						className="calendar-period-add-input calendar-period-add-input-date"
						onClick={() => {
							setShowDateDateTimePicker(true);
						}}
					>
						{requestedDate !== undefined
							? moment(requestedDate).format("DD-MMM-YYYY")
							: moment(new Date()).format("DD-MMM-YYYY")}
					</div>
				</div>
				<div className="calendar-period-add-row">
					<div className="calendar-period-add-label">
						{GetPrompts("Number of weeks")}:
					</div>
					<input
						id="calendar-period-add-input-id"
						type="text"
						className="calendar-period-add-input table-row-item"
						placeholder={12}
						defaultValue={
							periodInEdition !== undefined
								? periodInEdition.planCalendarPeriod.numberOfWeeks
								: numberOfWeeks
						}
						onChange={(e) => setNumberOfWeeks(e.target.value)}
					/>
				</div>
				<div className="calendar-period-add-row">
					<div className="calendar-period-add-label">
						{GetPrompts("Period title")}:
					</div>
					<input
						id="calendar-period-add-input-id"
						type="text"
						className="calendar-period-add-input table-row-item"
						placeholder={"Period"}
						defaultValue={
							periodInEdition !== undefined
								? periodInEdition.planCalendarPeriod.periodTitle
								: "Period"
						}
						onChange={(e) => setPeriodTitle(e.target.value)}
					/>
				</div>

				<div className="calendar-period-add-footer-btns">
					{isDefault === false ? (
						<div
							className="calendar-period-add-footer-btns-back"
							onClick={() => {
								setYesNoDlgOpen(true);
							}}
						>
							{GetPrompts("Delete text")}
						</div>
					) : (
						""
					)}

					{periodInEdition !== undefined ? (
						<div
							id="calendar-period-add-footer-btns-save"
							className="calendar-period-add-footer-btns-save"
							onClick={() => {
								if (isDefault === true) {
									CreatePeriod();
								} else {
									UpdatePeriod();
								}
							}}
						>
							{GetPrompts("Save text")}
						</div>
					) : (
						<div
							id="calendar-period-add-footer-btns-save"
							className="calendar-period-add-footer-btns-save"
							onClick={() => {
								CreatePeriod();
							}}
						>
							{GetPrompts("Save text")}
						</div>
					)}
				</div>
			</div>

			<DatePickerPopUp
				defaultValue={new Date(requestedDate)}
				open={showDateDateTimePicker}
				close={() => {
					setShowDateDateTimePicker(false);
				}}
				onChange={DateEditionHandler}
			/>
			<YesNoConfirm
				dlgOpen={yesNoDlgOpen}
				callBackFunction={DeletePeriod}
				onClose={() => setYesNoDlgOpen(false)}
				confirmMessage={"Seguro que quieres borrar este periodo?"}
			/>
		</>
	);
}
