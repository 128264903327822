import Moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Context } from "../../../../Context/Contexts";
import { GetPrompts } from "../../../Prompts/PromptsComponent";
import PlanFromSchedule from "../../PlanFromSchedule/PlanFromSchedule";
import "../DayOrderList/SortableActivity.css";
import "./WeeklyScheduleDatatable.css";
import SortableActivity from "../DayOrderList/SortableActivity";
import { WeeklyScheduleDayHeader } from "./WeeklyScheduleDayHeader";
export function WeeklyScheduleDatatable({
	weeklyScheduleList,
	setPlannedActivityInEdition,
	setIsOpen,
	currentDate,
	getPlannedActivities,
}) {
	if (Moment(currentDate).day() == 0) {
		currentDate = Moment(currentDate).day(-2);
	}
	const { activitiesInEditionList, setActivitiesInEditionList } =
		useContext(Context);
	const ctrlKeyPressed = useRef(false);
	const [smallScreenSchedule, setSmallScreenSchedule] = useState(false);
	const { showNewPlannedActivity, setShowNewPlannedActivity } =
		useContext(Context);
	const { hideWeekendInCalendar } = useContext(Context);

	const [selectedDate, setSelectedDate] = useState();

	const Monday = Moment(currentDate).day(1);
	const Tuesday = Moment(currentDate).day(2);
	const Wednesday = Moment(currentDate).day(3);
	const Thursday = Moment(currentDate).day(4);
	const Friday = Moment(currentDate).day(5);
	const Saturday = Moment(currentDate).day(6);
	const Sunday = Moment(currentDate).day(7);

	const SelectDate = (date) => {
		setSelectedDate(date);
	};
	const activityClickHandler = async (activity) => {
		if (ctrlKeyPressed.current) {
			await AddToActivitiesInEditionList(activity.plannerId);
			return;
		}

		setPlannedActivityInEdition(activity);
		setIsOpen(activity);
	};

	//Move to separate file
	const AddToActivitiesInEditionList = (id) => {
		return new Promise((resolve) => {
			let activity = null;
			for (const schedule of weeklyScheduleList) {
				for (const day in schedule) {
					const dayActivity = schedule[day];
					if (dayActivity && dayActivity.plannerId === id) {
						activity = dayActivity;
						break;
					}
				}
				if (activity) break;
			}

			if (!activity) {
				resolve();
				return;
			}

			setActivitiesInEditionList((prevList) => {
				const isPresent = prevList.some((item) => item.plannerId === id);
				if (isPresent) {
					return prevList.filter((item) => item.plannerId !== id);
				} else {
					return [...prevList, activity];
				}
			});
			resolve();
		});
	};

	const isSelectedForEdition = (id) => {
		return activitiesInEditionList.some((item) => item.plannerId === id);
	};

	const [windowSize, setWindowSize] = useState({
		width: undefined,
		height: undefined,
	});

	useEffect(() => {
		const handleMouseDown = (event) => {
			ctrlKeyPressed.current = event.ctrlKey;
		};

		window.addEventListener("mousedown", handleMouseDown);
		return () => {
			window.removeEventListener("mousedown", handleMouseDown);
		};
	}, []);

	var lastTime = "";
	var lastFromTime = "";
	useEffect(() => {
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}

		if (windowSize.width > 820) {
			setSmallScreenSchedule(false);
		} else {
			setSmallScreenSchedule(true);
		}

		// Add event listener
		window.addEventListener("resize", handleResize);
		// Call handler right away so state gets updated with initial window size
		handleResize();
		// Remove event listener on cleanup
		return () => window.removeEventListener("resize", handleResize);
	}, [windowSize.width]);

	const GetHourFormat = (hour) => {
		var returnHour = "";

		if (hour > 12) {
			returnHour = hour - 12 + ":00 pm";
		} else {
			returnHour = hour + ":00 am";
		}
		return returnHour;
	};

	if (Moment(currentDate).day() == 0) {
		currentDate = Moment(currentDate).day(-1);
	}

	return (
		<>
			<div className="container weekly-schedule-container">
				<table>
					<tr key={"weekly-schedule-time-header-key"}>
						<th className="weekschedule-day-header">
							{GetPrompts("Time Text")}
						</th>
						<th
							className="weekday-header"
							onClick={() => {
								SelectDate(Monday);
							}}
						>
							<WeeklyScheduleDayHeader
								dayText={GetPrompts("Monday Text")}
								currentDate={Monday}
							/>
						</th>
						<th
							className="weekday-header"
							onClick={() => {
								SelectDate(Tuesday);
							}}
						>
							<WeeklyScheduleDayHeader
								dayText={GetPrompts("Tuesday Text")}
								currentDate={Tuesday}
							/>
						</th>
						<th
							className="weekday-header"
							onClick={() => {
								SelectDate(Wednesday);
							}}
						>
							<WeeklyScheduleDayHeader
								dayText={GetPrompts("Wednesday Text")}
								currentDate={Wednesday}
							/>
						</th>
						<th
							className="weekday-header"
							onClick={() => {
								SelectDate(Thursday);
							}}
						>
							<WeeklyScheduleDayHeader
								dayText={GetPrompts("Thursday Text")}
								currentDate={Thursday}
							/>
						</th>
						<th
							className="weekday-header"
							onClick={() => {
								SelectDate(Friday);
							}}
						>
							<WeeklyScheduleDayHeader
								dayText={GetPrompts("Friday Text")}
								currentDate={Friday}
							/>
						</th>
						{!hideWeekendInCalendar ? (
							<>
								<th
									className="weekday-header"
									onClick={() => {
										SelectDate(Saturday);
									}}
								>
									<WeeklyScheduleDayHeader
										dayText={GetPrompts("Saturday Text")}
										currentDate={Saturday}
									/>
								</th>
								<th
									className="weekday-header"
									onClick={() => {
										SelectDate(Sunday);
									}}
								>
									<WeeklyScheduleDayHeader
										dayText={GetPrompts("Sunday Text")}
										currentDate={Sunday}
									/>
								</th>
							</>
						) : (
							<></>
						)}
					</tr>
					{weeklyScheduleList.map((schedRow) => {
						var timeString = schedRow.timeString.replaceAll(":00", "");
						var fromTime = GetHourFormat(schedRow.fromTime);
						var toTime = GetHourFormat(schedRow.toTime);
						var sameFromTime = false;
						var updateTime = true;

						if (fromTime == lastFromTime) {
							sameFromTime = true;
						}

						if (timeString != lastTime) {
							updateTime = true;
						} else {
							updateTime = false;
						}

						lastTime = timeString;
						lastFromTime = fromTime;

						return (
							<tr key={`"weekly-schedule-time-key"${timeString}`}>
								{smallScreenSchedule ? (
									updateTime == true ? (
										<td className="weekly-schedule-time">{fromTime}</td>
									) : (
										<td></td>
									)
								) : updateTime == true ? (
									<td>
										<span className="weekly-schedule-time">{timeString}</span>
									</td>
								) : (
									<td></td>
								)}
								<td>
									{schedRow.monday ? (
										<div onClick={() => activityClickHandler(schedRow.monday)}>
											<SortableActivity
												key={schedRow.monday.plannerId}
												id={schedRow.monday.plannerId}
												activity={schedRow.monday}
												isSelectedForEdition={isSelectedForEdition}
											/>
										</div>
									) : (
										""
									)}
								</td>
								<td>
									{schedRow.tuesday ? (
										<div onClick={() => activityClickHandler(schedRow.tuesday)}>
											<SortableActivity
												key={schedRow.tuesday.plannerId}
												id={schedRow.tuesday.plannerId}
												activity={schedRow.tuesday}
												isSelectedForEdition={isSelectedForEdition}
											/>
										</div>
									) : (
										""
									)}
								</td>
								<td>
									{schedRow.wednesday ? (
										<div
											onClick={() => activityClickHandler(schedRow.wednesday)}
										>
											<SortableActivity
												key={schedRow.wednesday.plannerId}
												id={schedRow.wednesday.plannerId}
												activity={schedRow.wednesday}
												isSelectedForEdition={isSelectedForEdition}
											/>
										</div>
									) : (
										""
									)}
								</td>
								<td>
									{schedRow.thursday ? (
										<div
											onClick={() => activityClickHandler(schedRow.thursday)}
										>
											<SortableActivity
												key={schedRow.thursday.plannerId}
												id={schedRow.thursday.plannerId}
												activity={schedRow.thursday}
												isSelectedForEdition={isSelectedForEdition}
											/>
										</div>
									) : (
										""
									)}
								</td>
								<td>
									{schedRow.friday ? (
										<div onClick={() => activityClickHandler(schedRow.friday)}>
											<SortableActivity
												key={schedRow.friday.plannerId}
												id={schedRow.friday.plannerId}
												activity={schedRow.friday}
												isSelectedForEdition={isSelectedForEdition}
											/>
										</div>
									) : (
										""
									)}
								</td>
								{!hideWeekendInCalendar ? (
									<>
										<td>
											{schedRow.saturday ? (
												<div
													onClick={() =>
														activityClickHandler(schedRow.saturday)
													}
												>
													<SortableActivity
														key={schedRow.saturday.plannerId}
														id={schedRow.saturday.plannerId}
														activity={schedRow.saturday}
														isSelectedForEdition={isSelectedForEdition}
													/>
												</div>
											) : (
												""
											)}
										</td>
										<td>
											{schedRow.sunday ? (
												<div
													onClick={() => activityClickHandler(schedRow.sunday)}
												>
													<SortableActivity
														key={schedRow.sunday.plannerId}
														id={schedRow.sunday.plannerId}
														activity={schedRow.sunday}
														isSelectedForEdition={isSelectedForEdition}
													/>
												</div>
											) : (
												""
											)}
										</td>
									</>
								) : (
									<></>
								)}
							</tr>
						);
					})}
				</table>
			</div>

			<PlanFromSchedule
				open={showNewPlannedActivity}
				close={() => {
					setShowNewPlannedActivity(false);
				}}
				defaultDate={selectedDate}
				isActivityWithTime={true}
			/>
		</>
	);
}
