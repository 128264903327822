import React from "react";
import Moment from "moment";
import "./PlannedActivitiesPerChildPerYear.css";
import {
	MODAL_STYLES,
	OVERLAY_STYLES,
} from "./PlannedActivitiesPerChildPerYear_ModalStyles";
import { RESPONSIVE_MODAL_STYLES } from "../../Modal_Styles/ResponsiveModalStyle";
import { GetPlannedActivitiesByChildByYear } from "./GetPlannedActivitiesByChildByYear";
import { useState } from "react";
import { useEffect } from "react";
import PlannedActivitiesPerChildPerYearCard from "../PlanFromSchedule/PlannedActivitiesPerChildPerYearCard";
import YearSelector from "../YearPlan/YearSelector/YearSelector";
import EditPlannedActivity from "../EditPlannedActivity/EditPlannedActivity";
import PerfectScrollbar from "react-perfect-scrollbar";

export default function PlannedActivitiesPerChildPerYear({
	isOpen,
	close,
	child,
}) {
	var savedMonth = 0;
	var updateMonth = false;

	const lang = sessionStorage.getItem("_lang");
	const [activitiesList, setActivitiesList] = useState([]);
	const [showYearSelector, setShowYearSelector] = useState(false);
	const [selectedYear, setSelectedYear] = useState();
	const [yearId, setYearId] = useState(0);
	const [openActivityEditor, setOpenActivityEditor] = useState(false);
	const [plannedActivityInEdition, setPlannedActivityInEdition] = useState();

	const GetActivities = (forceSelectedYear = false) => {
		var yearId = child.yearId;

		if (forceSelectedYear == true) {
			yearId = selectedYear.yearId;
		}

		GetPlannedActivitiesByChildByYear({
			childId: child.planChildId,
			yearId: yearId,
		}).then((response) => {
			setActivitiesList(response);
		});
	};

	useEffect(() => {
		if (isOpen) {
			setSelectedYear();
			GetActivities(false);
		}
	}, [isOpen]);

	useEffect(() => {
		if (selectedYear != undefined) {
			GetActivities(true);
		}
	}, [selectedYear]);

	if (!isOpen) return;

	return (
		<>
			<div style={OVERLAY_STYLES} onClick={close}></div>
			<PerfectScrollbar
				className="planned-activities-per-child-per-year-container"
				style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES)}
			>
				<div
					className="planned-activities-per-child-per-year-btn"
					onClick={close}
				>
					X
				</div>

				<div className="planned-activities-per-child-per-year-header">
					<div className="planned-activities-per-child-per-year-header-details">
						<span className="planned-activities-per-child-per-year-header-details-name base-sub-title">
							{child.firstName} {" - "}
						</span>

						<span
							className="planned-activities-per-child-per-year-header-details-year base-sub-title"
							onClick={() => {
								setShowYearSelector(true);
							}}
						>
							{selectedYear != undefined
								? selectedYear[`description${lang}`]
								: child.planYear[`description${lang}`]}
						</span>
					</div>
				</div>
				<div className="planned-activities-per-child-per-year-activities">
					{activitiesList.map((activity) => {
						var currentMonth = Moment(activity.date).format("MM");

						if (savedMonth != currentMonth) {
							updateMonth = true;
							savedMonth = currentMonth;
						} else {
							updateMonth = false;
						}

						return updateMonth == true ? (
							<>
								<div className="base-title-normal-height">
									{Moment(activity.date).format("MMM-YY")}
								</div>
								<div
									onClick={() => {
										setPlannedActivityInEdition(activity);
										setOpenActivityEditor(true);
									}}
								>
									{PlannedActivitiesPerChildPerYearCard(activity)}
								</div>
							</>
						) : (
							<div
								onClick={() => {
									setPlannedActivityInEdition(activity);
									setOpenActivityEditor(true);
								}}
							>
								{PlannedActivitiesPerChildPerYearCard(activity)}
							</div>
						);
					})}
				</div>
			</PerfectScrollbar>

			<YearSelector
				isopen={showYearSelector}
				close={() => setShowYearSelector(false)}
				selectedYear={selectedYear != undefined ? selectedYear : 0}
				setSelectedYear={(e) => {
					setSelectedYear(e);
				}}
			/>
			<EditPlannedActivity
				open={openActivityEditor}
				plannedActivity={plannedActivityInEdition}
				onClose={() => setOpenActivityEditor(false)}
				setUserId={0}
			/>
		</>
	);
}
