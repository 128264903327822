import Moment from "moment";
import axios from "../../../axios";
export const CreateNewPlannedActivity = async (
	newPlannedActivity,
	newPlannedChild,
	newPlannedActivityFrequency,
	newPlannedActivityFromDate,
	newPlannedActivityToDate,
	newPlannedActivityFromTime,
	newPlannedActivityToTime,
	weekdaysSelection,
	comments,
	activityType,
	yearId,
	formattedComments
) => {
	try {
		//validations
		var weeklyActivityType = 2;

		if (newPlannedActivity == 0) {
			return "Necesitas escoger al menos una actividad";
		}
		if (newPlannedChild == 0 || newPlannedChild == undefined) {
			return "Necesitas escoger al menos 1 estudiante";
		}
		if (
			newPlannedActivityFrequency == 0 ||
			newPlannedActivityFrequency == undefined
		) {
			return "Necesitas escoger la frecuencia de la actividad";
		}
		if (newPlannedActivityFrequency == weeklyActivityType) {
			if (isAtLeastOneDaySelected(weekdaysSelection) === false) {
				return "Debes escoger al menos un dia dentro de las opciones de la semana";
			}
		}
		var toDate = Moment(newPlannedActivityToDate);
		var fromDate = Moment(newPlannedActivityFromDate);
		var duration = Moment.duration(toDate.diff(fromDate));
		var days = duration.asDays();

		if (toDate < fromDate) {
			return "La fecha final debe ser mayor a la fecha inicial";
		}
		if (newPlannedActivityFrequency == 1) {
			if (DailyValidationsPassed(days) == false) {
				return "Actividades diarias es recomendable planear maximo 1 año";
			}
		}
		if (newPlannedActivityFrequency == 2) {
			if (WeeklyValidationsPassed(days) == false) {
				return "Actividades semanales es recomendable planear maximo 1 año";
			}
		}
		if (newPlannedActivityFrequency == 3) {
			if (MonthlyValidationsPassed(days) == false) {
				return "Actividades mensuales es recomendable planear maximo 1 año";
			}
		}

		if (newPlannedActivityFrequency == 4) {
			if (YearlyValidationsPassed(days) == false) {
				return "Actividades anuales es recomendable planear maximo 12 años";
			}
		}

		const url = `Planner/api/v1/CreateNewPlannedActivity`;

		const response = await axios.put(url, {
			UserId: 0,
			PlanChildId: newPlannedChild,
			ActivityId: newPlannedActivity,
			FrequencyId: newPlannedActivityFrequency,
			FromDate: Moment(newPlannedActivityFromDate).format(
				"YYYY-MM-DDTHH:mm:ss"
			),
			ToDate: Moment(newPlannedActivityToDate).format("YYYY-MM-DDTHH:mm:ss"),
			FromHour: parseInt(Moment(newPlannedActivityFromTime).format("h")),
			FromMinute: parseInt(Moment(newPlannedActivityFromTime).format("m")),
			FromAmPm: Moment(newPlannedActivityFromTime).format("a"),
			ToHour: parseInt(Moment(newPlannedActivityToTime).format("h")),
			ToMinute: parseInt(Moment(newPlannedActivityToTime).format("m")),
			ToAmPm: Moment(newPlannedActivityToTime).format("a"),
			WeeklyActivity: weekdaysSelection,
			TypeOfActivity: activityType,
			Comments: comments,
			FormattedComments: formattedComments,
			YearId: yearId,
		});

		return response.data;
	} catch (error) {}
};

const DailyValidationsPassed = (days) => {
	if (days > 365) {
		return false;
	}
	return true;
};

const WeeklyValidationsPassed = (days) => {
	if (days > 365) {
		return false;
	}
	return true;
};

const MonthlyValidationsPassed = (days) => {
	if (days > 365) {
		return false;
	}
	return true;
};
const YearlyValidationsPassed = (days) => {
	if (days > 365 * 12) {
		return false;
	}
	return true;
};

function isAtLeastOneDaySelected(weekdays) {
	for (let day in weekdays) {
		if (weekdays[day]) {
			return true;
		}
	}
	return false;
}
