import customAxios from "../../axios";

export const DuplicateActivity = async ({
	destinationCalendarWeek,
	plannedActivityId,
}) => {
	try {
		const url = `Planner/api/v1/DuplicateActivity`;

		const response = await customAxios.post(url, {
			UserId: 0,
			PlannerId: plannedActivityId,
			DestinationDate: destinationCalendarWeek,
		});
		return response.data;
	} catch (error) {}
};
