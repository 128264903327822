import { GetTransaction } from "./GetTransaction";

export async function WaitForTransactionToFinish({
	transactionId = 0,
	retries = 4,
	retryDelay = 3000,
}) {
	for (let attempt = 1; attempt <= retries; attempt++) {
		try {
			const data = await GetTransaction({ transactionId });
			console.log(data.result.status);
			if (data.result.status === 3) {
				console.log(`Attempt ${attempt}: Transaction Completed!`);
				return {
					success: true,
				};
			} else {
				console.log(
					`Attempt ${attempt}: Transaction not completed, retrying...`
				);
			}
		} catch (error) {
			console.error(
				`Attempt ${attempt}: Error fetching transaction, retrying...`,
				error
			);
		}

		if (attempt < retries) {
			await new Promise((resolve) => setTimeout(resolve, retryDelay));
		}
	}

	return {
		success: false,
		error: "Transaction failed after all retries.",
	};
}
