import "./WeeklySchedule.css";
import * as IoIcons from "react-icons/io";
import Moment from "moment";
import { GetPrompts } from "../../Components/Prompts/PromptsComponent";
import { useContext, useState } from "react";
import { Context } from "../../Context/Contexts";
import YesNoConfirm from "../../Components/ModalConfirm/YesNoConfirm";

import MoveWeek from "./MoveWeek/MoveWeek";
import { RevertPlannerAction } from "../../Components/RevertActions/RevertPlannerAction";
import { Typography } from "@mui/material";
import { MEMBERSHIP_UPGRADE_NEEDED } from "../../Components/Constants/Constants";
import { WaitForTransactionToFinish } from "../../Components/Transactions/WaitForTransaction";
import DuplicateWeekModal from "../../Components/DuplicateWeek/DuplicateWeekModal";

export function WeeklyScheduleControls({
	WeeklyScheduleDateEditionHandler,
	weeklyScheduleDate,
	setShowEditPlannedActivityMultiple,
	handlePrint,
	setShowDatePickerPopUp,
	weeklyScheduleDatePicker,
	weekNumber,
}) {
	const lang = sessionStorage.getItem("_lang");

	const { selectedChildGlobal } = useContext(Context);
	const { setShowIsloading, setShowIsloadingMessage } = useContext(Context);
	const { setShowDonecomponent } = useContext(Context);
	const { setShowSelectAllChildrenOption } = useContext(Context);
	const { setShowChildrenSelectorPopUp } = useContext(Context);
	const { setRefreshWeeklySchedule } = useContext(Context);
	const { setShowMembershipNeedUpgrade } = useContext(Context);
	const { hideWeekendInCalendar, setHideWeekendInCalendar } =
		useContext(Context);
	const { activitiesInEditionList, setActivitiesInEditionList } =
		useContext(Context);

	const [showConfirmation, setShowConfirmation] = useState(false);
	const [confirmationMessage, setConfirmationMessage] = useState("");
	const [confirmationCallBack, setConfirmationCallBack] = useState();

	const [showMoveWeek, setShowMoveWeek] = useState(false);
	const [showDuplicateWeekModal, setShowDuplicateWeekModal] = useState(false);

	const PreviousWeekClickHandler = () => {
		WeeklyScheduleDateEditionHandler(
			Moment(weeklyScheduleDate).add(-1, "weeks")
		);
	};
	const NextWeekClickHandler = () => {
		WeeklyScheduleDateEditionHandler(
			Moment(weeklyScheduleDate).add(1, "weeks")
		);
	};

	const RevertAction = () => {
		setShowIsloading(true);
		setShowIsloadingMessage(
			"Por favor espera, esta operacion puede tardar un poco..."
		);

		RevertPlannerAction().then(async (response) => {
			const transactionId = response.transactionId;
			await WaitForTransactionToFinish({
				transactionId: transactionId,
				retryDelay: 5000,
				retries: 24,
			}).then((response) => {
				if (response.success) {
					setRefreshWeeklySchedule(true);
					setShowDonecomponent(true);
					setShowIsloading(false);
				} else {
					//Todo: if no success after some tries say something, like we'll continue in the background
					console.log("Not done yet, we'll continue in the background...");
					setRefreshWeeklySchedule(true);
					setShowDonecomponent(true);
					setShowIsloading(false);
				}
			});
		});
	};

	return (
		<>
			<div className="weekly-schedule-controls">
				<div className="do-not-print">
					<span
						className="weekly-schedule-controls-back"
						onClick={PreviousWeekClickHandler}
						title="Semana anterior"
					>
						{"<< "}
					</span>
				</div>

				<div className="do-not-print weekly-schedule-dates-data">
					{selectedChildGlobal !== undefined ? (
						<div className="do-not-print weekly-schedule-controls-kid-name">
							<Typography variant="tableRow">
								{selectedChildGlobal.firstName}
								{" - "}
								{selectedChildGlobal.planYear[`description${lang}`]}
							</Typography>
						</div>
					) : (
						<></>
					)}
					<div
						className="weekly-schedule-date-input"
						onClick={() => {
							setShowDatePickerPopUp(true);
						}}
					>
						<div className="child-edition-form-input-text base-sub-title">
							{weekNumber === 0
								? `${GetPrompts("Week of")} - `
								: `${GetPrompts("Week")} #  ${weekNumber} - `}
							{Moment(weeklyScheduleDatePicker).format("DD/MM/YYYY")}{" "}
							{selectedChildGlobal != undefined ? (
								<span
									onClick={(event) => {
										event.stopPropagation();

										setShowDuplicateWeekModal(true);
									}}
									className="weekly-schedule-duplicate"
									title={`Duplicar semana para ${selectedChildGlobal.firstName}`}
								>
									<IoIcons.IoIosCopy />
								</span>
							) : (
								<></>
							)}
						</div>
					</div>

					<div className="report-print-item-weekly-schedule">
						<div className="report-print-item-weekly-schedule-btn-container">
							<div
								className="do-not-print hover-brighten report-print-item-btn base-button-text print-btn-weekly-schedule"
								onClick={() => {
									handlePrint();
								}}
							>
								{GetPrompts("Print Text")}
							</div>

							<div
								className="do-not-print hover-brighten select-child-weekly-schedule base-button-text"
								onClick={() => {
									setShowSelectAllChildrenOption(true);
									setShowChildrenSelectorPopUp(true);
								}}
								title="Seleccionar estudiantes"
							>
								<IoIcons.IoMdPeople />
							</div>

							<div
								className="do-not-print hover-brighten base-button-text hide-weekend-btn-weekly-schedule"
								onClick={() => {
									setHideWeekendInCalendar(!hideWeekendInCalendar);
								}}
								title={`${
									hideWeekendInCalendar
										? "Mostrar fin de semana"
										: "Ocultar fin de semana"
								}`}
							>
								{hideWeekendInCalendar ? (
									<>
										<IoIcons.IoMdEye /> {GetPrompts("Weekend")}
									</>
								) : (
									<>
										<IoIcons.IoMdEyeOff /> {GetPrompts("Weekend")}
									</>
								)}
							</div>

							<div
								className="do-not-print hover-brighten base-button-text move-week-btn"
								onClick={() => {
									setShowMoveWeek(true);
								}}
								title="Mover semana y actividades"
							>
								<>
									<IoIcons.IoIosArrowForward /> Mover
								</>
							</div>

							<div
								className="do-not-print hover-brighten base-button-text revert-btn"
								onClick={(event) => {
									event.stopPropagation();

									setConfirmationCallBack(() => RevertAction);
									setConfirmationMessage(
										"Seguro que quieres revertir tu ultima accion?"
									);
									setShowConfirmation(true);
								}}
								title="Revertir/Deshacer la ultima accion"
							>
								<>
									<IoIcons.IoMdUndo /> Revertir
								</>
							</div>

							{activitiesInEditionList.length > 0 ? (
								<div
									className="do-not-print base-button-text edit-selected-activities-btn-weekly-schedule"
									onClick={() => {
										setShowEditPlannedActivityMultiple(true);
									}}
								>
									<IoIcons.IoIosCreate /> Editar
								</div>
							) : (
								""
							)}
						</div>
					</div>
				</div>

				<div className="do-not-print ">
					<span
						className="weekly-schedule-controls-forward"
						onClick={NextWeekClickHandler}
						title="Siguiente semana"
					>
						{" >>"}
					</span>
				</div>
			</div>

			<YesNoConfirm
				dlgOpen={showConfirmation}
				callBackFunction={() => {
					confirmationCallBack();
					setShowConfirmation(false);
				}}
				onClose={() => {
					setConfirmationCallBack(() => {});
					setShowConfirmation(false);
				}}
				confirmMessage={confirmationMessage}
			/>
			<MoveWeek
				weeklyScheduleDate={weeklyScheduleDate}
				isOpen={showMoveWeek}
				closeHandler={() => {
					setShowMoveWeek(false);
				}}
			/>
			<DuplicateWeekModal
				isOpen={showDuplicateWeekModal}
				close={() => {
					setShowDuplicateWeekModal(false);
				}}
				weeklyScheduleDate={weeklyScheduleDate}
			/>
		</>
	);
}
