import Moment from "moment";
import React, { useContext, useEffect, useState } from "react";

import DatePickerPopUp from "../../Components/DatePicker/DatePickerPopUp";
import { WeeklyScheduleDatatableAll } from "../../Components/Planner/WeeklySchedule/Datatable/WeeklyScheduleDatatableAll";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { Context } from "../../Context/Contexts";
import "./WeeklySchedule.css";
import { GetPeriodForOneWeek } from "../../Components/Settings/Calendar/CrudPeriod/GetPeriodForOneWeek";
import EditPlannedActivityMultiple from "../../Components/Planner/EditPlannedActivity/Multpiple/EditPlannedActivityMultiple";
import { CalendarPrintHeader } from "./WeeklySchedulePrintHeader";
import { WeeklyScheduleControls } from "./WeeklyScheduleControls";
import { GetWeekInfo } from "../../Components/Settings/Calendar/CrudPeriod/GetWeekInfo";

export default function WeeklyScheduleAll({ handlePrint }) {
	var loadCount = 0;
	const lang = sessionStorage.getItem("_lang");

	const { activitiesInEditionList, setActivitiesInEditionList } =
		useContext(Context);

	const { setShowFooter } = useContext(Context);

	const { setShowNavbarItems, setShowSidebarToggle } = useContext(Context);
	const [showDatePickerPopUp, setShowDatePickerPopUp] = useState(false);
	const [weekNumber, setWeekNumber] = useState(undefined);
	const [skipWeekTitle, setSkipWeekTitle] = useState("");
	const [periodNumber, setPeriodNumber] = useState(0);
	const [periodTitle, setPeriodTitle] = useState("Term");
	const [showEditPlannedActivityMultiple, setShowEditPlannedActivityMultiple] =
		useState(false);

	useEffect(() => {
		setShowFooter(false);
		setShowNavbarItems(false);
		setShowSidebarToggle(true);
	});

	const WeeklyScheduleDateEditionHandler = (e) => {
		setWeeklyScheduleDatePicker(new Date(e));
		setWeeklyScheduleDate(Moment(e).format("YYYY-MM-DDTHH:mm:ss"));
	};
	const [weeklyScheduleDatePicker, setWeeklyScheduleDatePicker] = useState(
		new Date()
	);

	const [weeklyScheduleDate, setWeeklyScheduleDate] = useState(new Date());

	const GetWeek = () => {
		GetWeekInfo({
			requestedDate: Moment(weeklyScheduleDate).format("MM-DD-YYYY"),
		}).then((response) => {
			setWeekNumber(response.weekNumber);
			setSkipWeekTitle(response.weekTile);
		});
	};
	const GetPeriod = () => {
		GetPeriodForOneWeek({
			requestedDate: Moment(weeklyScheduleDate).format("MM-DD-YYYY"),
		}).then((response) => {
			setPeriodNumber(response.result.periodNumber);
			setPeriodTitle(response.result.planCalendarPeriod.periodTitle);
		});
	};
	useEffect(() => {
		GetWeek();
		GetPeriod();
		setActivitiesInEditionList([]);
	}, [weeklyScheduleDate]);

	useEffect(() => {
		if (loadCount > 0) {
			return;
		}
		InitialLoad();
	}, [loadCount]);

	const InitialLoad = () => {
		GetWeek();
		GetPeriod();
		setActivitiesInEditionList([]);
		loadCount = loadCount + 1;
	};

	return (
		<>
			<Sidebar />
			<div className="container weekly-schedule-page-container">
				<CalendarPrintHeader
					periodTitle={periodTitle}
					periodNumber={periodNumber}
					weekNumber={weekNumber}
					skipWeekTitle={skipWeekTitle}
				/>
				<WeeklyScheduleControls
					WeeklyScheduleDateEditionHandler={WeeklyScheduleDateEditionHandler}
					weeklyScheduleDate={weeklyScheduleDate}
					setShowEditPlannedActivityMultiple={
						setShowEditPlannedActivityMultiple
					}
					handlePrint={handlePrint}
					setShowDatePickerPopUp={setShowDatePickerPopUp}
					weeklyScheduleDatePicker={weeklyScheduleDatePicker}
					weekNumber={weekNumber}
				/>

				<div className="skip-week-tag-weekly-schedule do-not-print">
					{weekNumber === 0 ? (
						skipWeekTitle !== undefined ? (
							skipWeekTitle
						) : (
							"Semana de receso"
						)
					) : (
						<></>
					)}
				</div>

				<div className="weelkly-scheduler-table">
					<WeeklyScheduleDatatableAll weeklyScheduleDate={weeklyScheduleDate} />
				</div>
			</div>

			<EditPlannedActivityMultiple
				open={showEditPlannedActivityMultiple}
				onClose={() => setShowEditPlannedActivityMultiple(false)}
			/>

			<DatePickerPopUp
				open={showDatePickerPopUp}
				close={() => {
					setShowDatePickerPopUp(false);
				}}
				defaultValue={weeklyScheduleDatePicker}
				onChange={WeeklyScheduleDateEditionHandler}
			/>
		</>
	);
}
