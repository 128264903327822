import React, { useContext, useEffect, useState } from "react";
import { MODAL_STYLES, OVERLAY_STYLES } from "./Children_admin_ModalStyles";
import { RESPONSIVE_MODAL_STYLES } from "../../Modal_Styles/ResponsiveModalStyle";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./ChildreAdminGrid.css";
import { Context } from "../../../Context/Contexts";
import { GetChildren } from "../GetChildren";
import { Avatar, Typography } from "@mui/material";
import { GetPrompts } from "../../Prompts/PromptsComponent";
import ChildCard_Edit from "../ChildCard_Edit";
import PlannedActivitiesPerChildPerYear from "../../Planner/PlannedActivitiesPerChildPerYear/PlannedActivitiesPerChildPerYear";
import { EmptyChild } from "../EmptyChild";
import moment from "moment";
import { green } from "@mui/material/colors";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ChildrenAdmin({ open, close }) {
	const CloseClickHandler = () => {
		close();
	};

	var loadCount = 0;
	const [isOpen, setIsOpen] = useState(false);
	const { setShowIsloading } = useContext(Context);
	const [childrenList, setChildrenList] = useState([]);
	const [childInEdition, setChildInEdition] = useState("");
	const [showActivitiesPerYear, setShowActivitiesPerYear] = useState(false);

	const FeedChildrenList = () => {
		setShowIsloading(true);
		GetChildren({ currentPage: 0, itemsPerPage: 100 }).then(function (
			response
		) {
			setShowIsloading(false);
			setChildrenList(response.result);
		});
	};

	useEffect(() => {
		if (loadCount > 0) {
			return;
		}
		InitialLoad();
	}, [loadCount]);

	const InitialLoad = () => {
		FeedChildrenList();
		loadCount = loadCount + 1;
	};

	if (!open) return null;

	return (
		<>
			<div style={OVERLAY_STYLES} onClick={CloseClickHandler}></div>
			<PerfectScrollbar
				className="children-admin-container"
				style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES)}
			>
				<div className="children-admin-close-btn" onClick={CloseClickHandler}>
					X
				</div>

				<div className="children-admin-container">
					<div className="base-title">
						<Typography variant="baseTitle">
							{GetPrompts("My Students Text")}
						</Typography>
					</div>
					<>
						<PerfectScrollbar>
							<table className="children-home-table-admin">
								<tr className="children-home-table-header table-row-header">
									<th className="children-home-table-header-name">
										<Typography variant="tableHeader">
											{GetPrompts("Name text")}/{GetPrompts("Last Name text")}
										</Typography>
									</th>
									<th>
										<Typography variant="baseTitle">
											{GetPrompts("Date Of Birth Text")}
										</Typography>
									</th>
									<th>
										<Typography variant="baseTitle">
											{GetPrompts("Current year")}
										</Typography>
									</th>
								</tr>

								{childrenList.map((child) => {
									var selectedchildId = 0;
									return (
										<>
											<tr
												className={`children-admin-card-container`}
												key={`children-home-card-container-key${child.planChildId}`}
											>
												<td className="children-home-card-name table-row-item">
													<div className="children-home-card-name-avatar">
														<Avatar sx={{ bgcolor: green[200] }}>
															<span className="children-home-card-name-avatar-text">
																{child.firstName.charAt(0)}
															</span>
														</Avatar>
													</div>
													<div className="children-home-card-name-text table-row-item">
														<Typography variant="tableRow">
															{child.firstName} {child.lastName}
														</Typography>
													</div>
												</td>
												<td className="children-home-card-dob table-row-item">
													<Typography variant="tableRow"></Typography>
													{moment(child.dateOfBirth).format("DD/MM/YYYY")}
												</td>
												<td className="children-home-card-year-text table-row-item">
													<Typography variant="tableRow">
														{child.planYear.yearId}
													</Typography>
												</td>
											</tr>

											<div className="children-home-card-btn-container">
												<div
													className="children-home-card-btn-edit"
													onClick={() => {
														setChildInEdition(child);
														setIsOpen(true);
													}}
												>
													<div className="children-home-card-btn-text base-button-text">
														<Typography variant="tableRow">
															{GetPrompts("Edit text")}
														</Typography>
													</div>
												</div>

												<div
													className=" children-home-card-btn-text children-home-card-btn-edit-view-plan"
													onClick={() => {
														setChildInEdition(child);
														setShowActivitiesPerYear(true);
													}}
												>
													<div className="base-button-text">
														{GetPrompts("View plan")}
													</div>
												</div>
											</div>
										</>
									);
								})}

								<tr className={"children-home-card-container"}>
									<td className="children-home-card-name">
										<div className="children-home-card-name-avatar">
											<span
												id="children-home-card-add"
												className="children-home-card-add"
												onClick={() => {
													const emptyChild = EmptyChild;
													setChildInEdition(emptyChild);
													setIsOpen(true);
												}}
											>
												<Typography variant="h6">
													<FontAwesomeIcon icon={faPlus} />
													{GetPrompts("Add")}
												</Typography>
											</span>
										</div>
										<div className="children-home-card-name-text"></div>
									</td>
									<td className="children-home-card-dob">...</td>
									<td className="children-home-card-year-text">...</td>
								</tr>
							</table>
						</PerfectScrollbar>
					</>
				</div>
			</PerfectScrollbar>

			<ChildCard_Edit
				open={isOpen}
				child={childInEdition}
				onClose={() => setIsOpen(false)}
				getChildren={FeedChildrenList}
				setUserId={0}
			/>

			<PlannedActivitiesPerChildPerYear
				isOpen={showActivitiesPerYear}
				close={() => {
					setShowActivitiesPerYear(false);
				}}
				child={childInEdition}
			/>
		</>
	);
}
