import React, { useState } from "react";
import { MODAL_STYLES, OVERLAY_STYLES } from "./SkipWeekUpdate_ModalStyle";
import { RESPONSIVE_MODAL_STYLES } from "../../../../Modal_Styles/ResponsiveModalStyle";
import "./SkipWeekUpdate.css";
import { UpdateSkipWeek } from "./UpdateSkipWeek";

export default function SkipWeekUpdate({
	isOpen,
	closeHandler,
	skipWeekInEdition,
	currentSkipTextToShow,
	callBack,
}) {
	const [skipTitle, setSkipTitle] = useState("");

	const handleSend = () => {
		if (skipTitle === "" || skipTitle === undefined) {
			return;
		}
		UpdateSkipWeek({
			calendarWeek: skipWeekInEdition.periodWeek,
			skipTitle: skipTitle,
		}).then((response) => {
			callBack();
		});
	};
	if (!isOpen) return null;
	return (
		<>
			<div
				style={OVERLAY_STYLES}
				onClick={() => {
					closeHandler();
				}}
			></div>
			<div
				className="update-skip-week-container"
				style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES)}
			>
				<div
					className="update-skip-week-close-btn"
					onClick={() => {
						closeHandler();
					}}
				>
					X
				</div>

				<div className="update-skip-week-row">
					<div className="update-skip-week-label">Texto:</div>
					<div className="update-skip-week-input-container">
						<input
							id="update-skip-week-input-text"
							type="text"
							className="update-skip-week-input"
							placeholder={"Texto para mostrar en el calendario"}
							defaultValue={currentSkipTextToShow}
							onChange={(e) => setSkipTitle(e.target.value)}
						/>
					</div>
				</div>
				<div className="update-skip-week-btn-container">
					<div
						className="update-skip-week-btn-send hover-brighten"
						onClick={() => {
							handleSend();
						}}
					>
						Guardar
					</div>
				</div>
			</div>
		</>
	);
}
