import React, { useState } from "react";
import Calendar from "react-calendar";
import "./DatePickerPopUp.css";
import { MODAL_STYLES, OVERLAY_STYLES } from "./DatePickerPopUp_ModalStyle";

export default function YearPickerPopUp({
	open,
	close,
	defaultValue,
	onChange,
}) {
	const [defaultYear, setDefaultYear] = useState(new Date());

	const OnYearChangeHandler = (value) => {
		// Extract the year from the selected date
		const selectedYear = value.getFullYear();
		onChange(selectedYear); // Pass the year to the parent component
		close(); // Close the modal
	};

	const CloseClickHandler = () => {
		close();
	};

	if (!open) return null;

	return (
		<>
			<article>
				<div
					id="year-picker-pop-up-close-btn"
					style={OVERLAY_STYLES}
					onClick={CloseClickHandler}
				></div>

				<div className="year-picker-pop-up-container" style={MODAL_STYLES}>
					<Calendar
						defaultView="decade" // Show the decade view to pick a year
						maxDetail="decade" // Restrict to decade view
						minDetail="decade" // Restrict to decade view
						onChange={OnYearChangeHandler} // Handle year selection
						value={defaultValue ?? defaultYear}
					/>
				</div>
			</article>
		</>
	);
}
