import { faTasks } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { Context } from "../../../../../Context/Contexts";
import { RESPONSIVE_MODAL_STYLES } from "../../../../Modal_Styles/ResponsiveModalStyle";
import { GetYearActivitiesALL } from "../../YearActivities/GetYearActivities_All";
import YearPlanActivitiesSelectorCard from "../YearPlanActivitiesSelectorCard";
import "./YearPlanActivitiesSelector.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import {
	MODAL_STYLES,
	OVERLAY_STYLES,
} from "./YearPlanActivitiesSelector_ModalStyles";

export function YearPlanActivitiesSelector_Icon({ setOpenSelector }) {
	const { showToolTip, setShowToolTip } = useContext(Context);
	return (
		<>
			<div data-tip data-for={"year-plan-activities-selector-icon"}>
				{showToolTip ? (
					<ReactTooltip id={"year-plan-activities-selector-icon"}>
						{"Escoge una actividad"}
					</ReactTooltip>
				) : (
					""
				)}

				<FontAwesomeIcon
					onMouseEnter={() => setShowToolTip(true)}
					onMouseLeave={() => setShowToolTip(false)}
					onClick={() => setOpenSelector(true)}
					icon={faTasks}
					className={"year-plan-activities-selector-icon"}
				/>
			</div>
		</>
	);
}
export default function YearPlanActivitiesSelector({
	setSelectedActivity,
	setActivitiesList,
	openSelector,
	setOpenSelector,
}) {
	const [listForFiltering, setListForFiltering] = useState([]);
	const { showToolTip, setShowToolTip } = useContext(Context);
	const [refresh, setRefresh] = useState(false);
	const [currentSelectionArr, setCurrentSelectionArr] = useState([]);
	const { isloading, setIsloading } = useContext(Context);
	const { setShowIsloading } = useContext(Context);
	const [yearPlanActivityList, setYearPlanActivityList] = useState([]);
	const [filterby, setFilterby] = useState("");

	const GetActivities = () => {
		setShowIsloading(true);
		GetYearActivitiesALL({}).then(function (response) {
			setShowIsloading(false);

			setYearPlanActivityList(response.result);
			setListForFiltering(response.result);

			setActivitiesList(response.result);
		});
	};

	const AddSelectedItem = (itemId) => {
		var currselection = currentSelectionArr;
		var itemFound = false;
		currselection.filter((val) => {
			if (val == itemId) {
				itemFound = true;
				var index = currselection.indexOf(itemId);
				currselection.splice(index, 1);
			}
		});

		if (!itemFound) {
			currselection = []; //No multiselect
			currselection.push(itemId);
		}

		setCurrentSelectionArr(currselection);
		setRefresh(!refresh);

		setTimeout(() => setOpenSelector(false), 300);
	};

	const SelectActivity = (activityId) => {
		AddSelectedItem(activityId.activityId);
		setSelectedActivity(activityId);
	};

	useEffect(() => {
		if (openSelector) {
			GetActivities();
		} else {
			setFilterby();
		}
	}, [openSelector]);
	useEffect(() => {
		if (openSelector) {
			ApplyFilter();
		}
	}, [filterby]);

	const ApplyFilter = () => {
		var filteredJsonArray = listForFiltering.filter((item) => {
			return (
				item.activity.description
					.toLowerCase()
					.includes(filterby.toLowerCase()) ||
				item.activity.descriptionHint
					.toLowerCase()
					.includes(filterby.toLowerCase())
			);
		});
		setYearPlanActivityList(filteredJsonArray);
	};

	const Filter = ({ value }) => {
		const handleChange = (event) => {
			setFilterby(event.target.value);
		};

		return (
			<div className="year-plan-activities-selector-filter-container">
				<input
					className="year-plan-activities-selector-filter base-parafraph"
					autoFocus
					type="text"
					onChange={handleChange}
					value={filterby}
					placeholder="Filtrar..."
				/>
			</div>
		);
	};

	return (
		<>
			{openSelector ? (
				<div>
					<div
						style={OVERLAY_STYLES}
						onClick={() => setOpenSelector(false)}
					></div>
					<PerfectScrollbar
						className=" year-plan-activities-selector-container"
						style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES)}
					>
						<div
							className="year-plan-activities-selector-close-btn"
							onClick={() => setOpenSelector(false)}
						>
							X
						</div>
						<div className="year-plan-activities-selector-header">
							<Filter />
						</div>

						{yearPlanActivityList.map((activity) => {
							var index = currentSelectionArr.indexOf(
								activity.activity.activityId
							);

							if (index == -1) {
								return YearPlanActivitiesSelectorCard(
									activity,
									SelectActivity,
									false
								);
							} else {
								return YearPlanActivitiesSelectorCard(
									activity,
									SelectActivity,
									true
								);
							}
						})}
					</PerfectScrollbar>
				</div>
			) : (
				""
			)}
		</>
	);
}
