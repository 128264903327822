import customAxios from "../../../axios";

export const DuplicateWeekToAnotherWeek = async ({
	calendarWeek,
	destinationCalendarWeek,
	deleteExistingActivities,
	childId,
}) => {
	try {
		const url = `Planner/api/v1/DuplicateWeekToAnotherWeek`;

		const response = await customAxios.post(url, {
			UserId: 0,
			CalendarWeek: calendarWeek,
			DestinationCalendarWeek: destinationCalendarWeek,
			DeleteExistingActivities: deleteExistingActivities,
			ChildId: childId,
		});
		return response.data;
	} catch (error) {}
};
