import Moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Context } from "../../../Context/Contexts";
import axios from "../../../axios";
import {
	MODAL_STYLES,
	OVERLAY_STYLES,
} from "./NewYearPlannedActivity_ModalStyle";

import "./NewYearPlannedActivity.css";

import * as IoIcons from "react-icons/io";
import Spinner from "react-spinkit";
import DatePickerPopUp from "../../DatePicker/DatePickerPopUp";
import ErrorPopUp from "../../Errors/ErrorPopUp";
import FrequencySelector from "../../Frequency/FrequencySelector";
import {
	MODAL_STYLES_BACKGROUND,
	RESPONSIVE_MODAL_STYLES,
} from "../../Modal_Styles/ResponsiveModalStyle";
import { GetPrompts } from "../../Prompts/PromptsComponent";
import {
	PLANNER_SETTING_FROM_DATE,
	PLANNER_SETTING_FROM_TIME,
	PLANNER_SETTING_TO_DATE,
	PLANNER_SETTING_TO_TIME,
} from "../../Reducer/Actions";
import { SPINNER_STYLES_ORANGE } from "../../Spinner/SpinnerStyles";
import TimePickerPopUp from "../../TimePicker/TimePickerPopUp";
import { CreateNewPlannedActivity } from "../Actions/CreateNewPlannedActivity";
import WeekdaysSelector from "../WeekdaysSelector/WeekdaysSelector";
import IsLoadingComponent from "../../Isloading/IsLoadingComponent";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
	quillEditorExtractTextFromHtml,
	quillEditorFormats,
	quillEditorModules,
	quillEditorSimpleModules,
} from "../../RichTextEditor/RichTextEditor";
import { WaitForTransactionToFinish } from "../../Transactions/WaitForTransaction";

export default function NewYearPlannedActivity({
	open,
	plannedActivity,
	onClose,
	GetPlannedActivities,
	selectedChild,
	selectedChildDisplay,
	isActivityWithTime = false,
	currentYearId = 0,
}) {
	const { state, dispatch } = useContext(Context);

	const { setShowDonecomponent } = useContext(Context);

	const lang = sessionStorage.getItem("_lang");
	const [errorPopUpIsOpen, setErrorPopUpIsOpen] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const [showTime, setShowTime] = useState(isActivityWithTime);
	const [showStartDateTimePicker, setShowStartDateTimePicker] = useState(false);
	const [showEndDateTimePicker, setShowEndDateTimePicker] = useState(false);
	const [showFromTimePicker, setShowFromTimePicker] = useState(false);
	const [showToTimePicker, setShowToTimePicker] = useState(false);

	const { showIsloading, setShowIsloading } = useContext(Context);

	const { setRefreshYearPlanner } = useContext(Context);

	const userId = 0;
	const [dlgOpen, setDlgOpen] = useState(false);
	const [description, setDescription] = useState();
	const [plannedDate, setPlannedDate] = useState();
	const [fromTime, setFromTime] = useState();
	const [toTime, setToTime] = useState();
	const [comment, setComment] = useState("");

	const [newPlannedActivity, setNewPlannedActivity] = useState();

	const [newPlannedChild, setNewPlannedChild] = useState();

	const [newPlannedActivityFrequency, setNewPlannedActivityFrequency] =
		useState();
	const [newPlannedActivityFromDate, setNewPlannedActivityFromDate] = useState(
		state.plannerSettings.fromDate ? state.plannerSettings.fromDate : new Date()
	);
	const [newPlannedActivityToDate, setNewPlannedActivityToDate] = useState(
		state.plannerSettings.toDate ? state.plannerSettings.toDate : new Date()
	);
	const [newPlannedActivityFromTime, setNewPlannedActivityFromTime] = useState(
		state.plannerSettings.fromTime ? state.plannerSettings.fromTime : new Date()
	);
	const [newPlannedActivityToTime, setNewPlannedActivityToTime] = useState(
		state.plannerSettings.toTime ? state.plannerSettings.toTime : new Date()
	);

	const [plannedDatePicker, setPlannedDatePicker] = useState(new Date());
	const [fromTimeDatePicker, setFromTimeDatePicker] = useState(new Date());
	const [toTimeDatePicker, setToTimeDatePicker] = useState(new Date());

	const [childrenList, setChildrenList] = useState([]);
	const [activitiesList, setActivitiesList] = useState([]);
	const [frequencyList, setFrequencyList] = useState([]);
	const [weekdaysSelection, setWeekdaysSelection] = useState();

	const FeedEditionVariables = async (e) => {
		if (open) {
			setNewPlannedActivityFrequency(0);
			PlannedDateEditionHandler(new Date());
			FromTimeEditionHandler(new Date());
			ToTimeEditionHandler(new Date());

			setNewPlannedChild(selectedChild);
			setDescription(plannedActivity.description);
		}
	};

	const GetActivitiesDropdowns = async (e) => {
		try {
			if (open) {
				const url = `Planner/api/v1/GetPlannedActivitiesDropdowns`;

				setShowIsloading(true);

				const response = await axios.get(url);

				setShowIsloading(false);
				setActivitiesList(response.data.result.planActivities);
				setFrequencyList(response.data.result.frequencies);
				setChildrenList(response.data.result.children);
			}
		} catch (error) {
			setShowIsloading(false);
		}
	};

	function ShowHideTime() {
		return (
			<>
				<div
					className={
						!showTime
							? "new-planned-year-activity-card-show-time"
							: "new-planned-year-activity-card-hide-time"
					}
					onClick={() => {
						setShowTime(!showTime);
					}}
				>
					<IoIcons.IoMdClock />
				</div>
			</>
		);
	}

	useEffect(() => {
		GetActivitiesDropdowns();
		FeedEditionVariables();
	}, [open]);

	const CreateClickHandler = async () => {
		setShowIsloading(true);
		var activityType = "Order";
		var noFormatComment = quillEditorExtractTextFromHtml(comment);
		if (showTime == true) {
			activityType = "Time";
		}
		await CreateNewPlannedActivity(
			plannedActivity.activityId,
			newPlannedChild,
			newPlannedActivityFrequency,
			newPlannedActivityFromDate,
			newPlannedActivityToDate,
			newPlannedActivityFromTime,
			newPlannedActivityToTime,
			weekdaysSelection,
			noFormatComment,
			activityType,
			currentYearId,
			comment
		).then(async (response) => {
			if (response.error != null) {
				setErrorMessage(response.error);
				setErrorPopUpIsOpen(true);
				setShowIsloading(false);
				return;
			} else {
				const transactionId = response.transactionId;
				await WaitForTransactionToFinish({
					transactionId: transactionId,
					retryDelay: 3000,
					retries: 20,
				}).then(async (response) => {
					if (response.success) {
						setComment("");
						setShowDonecomponent(true);
						await GetPlannedActivities();
						onClose();
						setShowIsloading(false);
						setRefreshYearPlanner(true);
					} else {
						console.log("Not done yet, we'll continue in the background...");
						setComment("");
						setShowDonecomponent(true);
						await GetPlannedActivities();
						onClose();
						setShowIsloading(false);
						setRefreshYearPlanner(true);
					}
				});
			}
		});
	};

	const ResetDateTimePickers = () => {
		setNewPlannedActivityFromDate(new Date());
		setNewPlannedActivityToDate(new Date());
		setNewPlannedActivityFromTime(new Date());
		setNewPlannedActivityToTime(new Date());
	};
	const CloseClickHandler = () => {
		onClose();
	};

	const PlannedDateEditionHandler = (e) => {
		setPlannedDatePicker(e);
		setPlannedDate(Moment(e).format("YYYY-MM-DDTHH:mm:ss"));
	};
	const FromTimeEditionHandler = (e) => {
		setFromTimeDatePicker(e);
		setFromTime(Moment(e).format("YYYY-MM-DDTHH:mm:ss"));
	};
	const ToTimeEditionHandler = (e) => {
		setToTimeDatePicker(e);
		setToTime(Moment(e).format("YYYY-MM-DDTHH:mm:ss"));
	};

	const NewPlannedFromDateEditionHandler = (e) => {
		var newDate = new Date(e);
		setNewPlannedActivityFromDate(newDate);
		dispatch({ type: PLANNER_SETTING_FROM_DATE, payload: { newDate } });

		if (newDate > newPlannedActivityToDate) {
			NewPlannedToDateEditionHandler(newDate);
		}
	};
	const NewPlannedToDateEditionHandler = (e) => {
		var newDate = new Date(e);
		setNewPlannedActivityToDate(newDate);
		dispatch({ type: PLANNER_SETTING_TO_DATE, payload: { newDate } });

		if (newDate < newPlannedActivityFromDate) {
			NewPlannedFromDateEditionHandler(newDate);
		}
	};
	const NewPlannedFromTimeEditionHandler = (e) => {
		var newTime = e;
		setNewPlannedActivityFromTime(newTime);
		dispatch({ type: PLANNER_SETTING_FROM_TIME, payload: { newTime } });
	};
	const NewPlannedToTimeEditionHandler = (e) => {
		var newTime = e;
		setNewPlannedActivityToTime(newTime);
		dispatch({ type: PLANNER_SETTING_TO_TIME, payload: { newTime } });
	};

	const NewActivitySelectionHandler = (e) => {
		setNewPlannedActivity(e);
	};
	const NewChildSelectionHandler = (e) => {
		setNewPlannedChild(e);
	};

	const FrequencyEditionHandler = async (e) => {
		setNewPlannedActivityFrequency(e);
	};

	if (!open) return null;
	return (
		<article key={plannedActivity.plannerId}>
			<div style={OVERLAY_STYLES} onClick={CloseClickHandler}></div>
			<div
				className="modal-container-image"
				style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES_BACKGROUND)}
			></div>

			<div
				className="new-planned-year-activity-card-container"
				style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES)}
			>
				<>
					<div
						className="new-planned-year-activity-card-close-btn"
						onClick={CloseClickHandler}
					>
						X
					</div>

					<div className="new-planned-year-activity-card-title">
						{GetPrompts("Create plan")}
					</div>
					<div className="new-planned-year-activity-card-read-only-container-div">
						<div className="new-planned-year-activity-card-read-only-container">
							<span className="new-planned-year-activity-card-read-only-item ">
								{GetPrompts("Student Text")}:{" "}
								<span className="base-parafraph">{selectedChildDisplay}</span>
							</span>
							<span className="new-planned-year-activity-card-read-only-item">
								{GetPrompts("School Subject text")}:{" "}
								<span className="base-parafraph">
									{plannedActivity.description}
								</span>
							</span>
						</div>
					</div>

					<div className="new-planned-year-activity-card-frequency-container">
						<div className="new-planned-year-activity-card-label-item">
							{GetPrompts("Frequency Text")}:
						</div>
						<div className="new-planned-year-activity-card-dropdown">
							<FrequencySelector
								selectedFrequency={newPlannedActivityFrequency}
								setSelectedFrequency={(e) => {
									setNewPlannedActivityFrequency(e);
								}}
							/>
						</div>
					</div>

					{newPlannedActivityFrequency == 2 ? (
						<div className="new-planned-year-activity-card-row-container">
							<div className="new-planned-year-activity-card-WeekdaysSelector-content-container">
								<WeekdaysSelector setWeekdaysSelection={setWeekdaysSelection} />
							</div>
						</div>
					) : (
						<></>
					)}

					<div className="new-planned-year-activity-card-date-picker-container">
						<span className="new-planned-year-activity-card-label-item">
							{GetPrompts("Starts Text")}:
						</span>
						<div className="new-planned-year-activity-card-date-picker">
							<div
								id="new-planned-year-activity-card-datetime-picker-start"
								className="new-planned-year-activity-card-datetime-picker"
								onClick={() => {
									setShowStartDateTimePicker(true);
								}}
							>
								{Moment(newPlannedActivityFromDate).format("DD/MMM/yyyy")}
							</div>
						</div>
						{ShowHideTime()}
						{showTime ? (
							<div className="new-planned-year-activity-card-time-picker">
								<div
									className="new-planned-year-activity-card-datetime-time-picker"
									onClick={() => {
										setShowFromTimePicker(true);
									}}
								>
									{Moment(newPlannedActivityFromTime).format("h:mm a")}
								</div>
							</div>
						) : (
							""
						)}
					</div>

					<div className="new-planned-year-activity-card-date-picker-container">
						<span className="new-planned-year-activity-card-label-item">
							{GetPrompts("Ends Text")}
						</span>
						<div className="new-planned-year-activity-card-date-picker">
							<div
								id="new-planned-year-activity-card-datetime-picker-end"
								className="new-planned-year-activity-card-datetime-picker"
								onClick={() => {
									setShowEndDateTimePicker(true);
								}}
							>
								{Moment(newPlannedActivityToDate).format("DD/MMM/yyyy")}
							</div>
						</div>
						{ShowHideTime()}
						{showTime ? (
							<div className="new-planned-year-activity-card-time-picker">
								<div
									className="new-planned-year-activity-card-datetime-time-picker"
									onClick={() => {
										setShowToTimePicker(true);
									}}
								>
									{Moment(newPlannedActivityToTime).format("h:mm a")}
								</div>
							</div>
						) : (
							""
						)}
					</div>

					<div className="new-planned-year-activity-card-container-div">
						<ReactQuill
							id="new-planned-year-activity-card-comment-content"
							className="new-planned-year-activity-card-comment-content base-parafraph borderless-quill"
							onChange={setComment}
							placeholder={GetPrompts("Details about the activity")}
							theme="snow"
							modules={quillEditorSimpleModules}
							formats={quillEditorFormats}
						/>
					</div>

					<div
						id="new-planned-year-activity-card-save-btn"
						className="new-planned-year-activity-card-save-btn hover-brighten"
						onClick={CreateClickHandler}
					>
						<span className="new-planned-year-activity-card-save-btn-text">
							{GetPrompts("Create plan")}
						</span>
					</div>
				</>
			</div>

			<ErrorPopUp
				open={errorPopUpIsOpen}
				onClose={() => setErrorPopUpIsOpen(false)}
				errorMessage={errorMessage}
			/>

			<DatePickerPopUp
				open={showStartDateTimePicker}
				close={() => {
					setShowStartDateTimePicker(false);
				}}
				defaultValue={newPlannedActivityFromDate}
				onChange={NewPlannedFromDateEditionHandler}
			/>

			<DatePickerPopUp
				open={showEndDateTimePicker}
				close={() => {
					setShowEndDateTimePicker(false);
				}}
				defaultValue={newPlannedActivityToDate}
				onChange={NewPlannedToDateEditionHandler}
			/>

			<TimePickerPopUp
				open={showFromTimePicker}
				close={() => {
					setShowFromTimePicker(false);
				}}
				defaultValue={newPlannedActivityFromTime}
				onChange={NewPlannedFromTimeEditionHandler}
			/>
			<TimePickerPopUp
				open={showToTimePicker}
				close={() => {
					setShowToTimePicker(false);
				}}
				defaultValue={newPlannedActivityToTime}
				onChange={NewPlannedToTimeEditionHandler}
			/>
		</article>
	);
}
