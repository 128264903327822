import React, { useContext, useEffect, useState } from "react";
import { MODAL_STYLES, OVERLAY_STYLES } from "./CalendarSettings_ModalStyle";
import { RESPONSIVE_MODAL_STYLES } from "../../Modal_Styles/ResponsiveModalStyle";
import { Context } from "../../../Context/Contexts";
import { GetPlanPeriodsByUser } from "./GetPlanPeriodsByUser";
import CalendarPeriods from "./CalendarPeriods";
import DatePickerPopUp from "../../DatePicker/DatePickerPopUp";
import moment from "moment";
import "./CalendarSettings.css";
import CalendarPeriodsCrud from "./CrudPeriod/CalendarPeriodsCrud";
import { GetPrompts } from "../../Prompts/PromptsComponent";
import YearPickerPopUp from "../../DatePicker/YearPickerPopUp";

export default function CalendarSettings() {
	var loadCount = 0;
	const { calendarSettingsIsOpen, setCalendarSettingsIsOpen } =
		useContext(Context);

	const [requestedDate, setRequestedDate] = useState(new Date());
	const [requestedDatePicker, setRequestedDatePicker] = useState(new Date());
	const [showAddPeriod, setShowAddPeriod] = useState(false);
	const [showEditPeriod, setShowEditPeriod] = useState(false);
	const [periodInEdition, setPeriodInEdition] = useState(undefined);

	const [periodData, setPeriodData] = useState("");

	const [showDateDateTimePicker, setShowDateDateTimePicker] = useState(false);

	const CloseClickHandler = () => {
		setCalendarSettingsIsOpen(false);
	};
	const GetPeriodData = () => {
		GetPlanPeriodsByUser({ requestedDate: requestedDate }).then((response) => {
			setPeriodData(response.result);
		});
	};
	const DateEditionHandler = (e) => {
		var myDate = new Date(e, 1, 1);

		setRequestedDatePicker(myDate);
		setRequestedDate(moment(myDate).format("YYYY-MM-DDTHH:mm:ss"));
	};

	useEffect(() => {
		GetPeriodData();
	}, [requestedDate]);

	useEffect(() => {
		if (loadCount > 0) {
			return;
		}
		GetPeriodData();
	}, [loadCount]);

	if (!calendarSettingsIsOpen) return null;

	return (
		<>
			<div style={OVERLAY_STYLES} onClick={CloseClickHandler}></div>
			<div
				className="calendar-settings-container"
				style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES)}
			>
				<div
					className="calendar-settings-close-btn"
					onClick={CloseClickHandler}
				>
					X
				</div>

				<div>
					<div className="calendar-settings-label table-row-header">
						{GetPrompts("Year")}:
					</div>
					<div
						className="calendar-settings-year-input"
						onClick={() => {
							setShowDateDateTimePicker(true);
						}}
					>
						<div className="calendar-settings-year-input-text">
							{requestedDate != undefined
								? moment(requestedDate).format("YYYY")
								: ""}
						</div>
					</div>
					<div>
						<CalendarPeriods
							periodData={periodData}
							setShowAddPeriod={setShowAddPeriod}
							setPeriodInEdition={(val) => {
								setPeriodInEdition(val);
								setShowEditPeriod(true);
							}}
							getPeriodData={GetPeriodData}
						/>
					</div>
				</div>
			</div>

			<CalendarPeriodsCrud
				open={showAddPeriod}
				close={() => {
					setShowAddPeriod(false);
				}}
				getPeriodData={GetPeriodData}
			/>
			<CalendarPeriodsCrud
				open={showEditPeriod}
				close={() => {
					setShowEditPeriod(false);
				}}
				getPeriodData={GetPeriodData}
				periodInEdition={periodInEdition}
			/>

			<YearPickerPopUp
				open={showDateDateTimePicker}
				close={() => {
					setShowDateDateTimePicker(false);
				}}
				onChange={DateEditionHandler}
				defaultView="year"
			/>
		</>
	);
}
