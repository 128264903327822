export const MODAL_STYLES = {
	position: "fixed",
	borderRadius: "30px",
	width: "400px",
	height: "200px",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	backgroundColor: "#FFF",
	padding: "50px",
	zIndex: 1000,
};
export const OVERLAY_STYLES = {
	position: "fixed",
	width: "100%",
	height: "100%",
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	backgroundColor: "rgba(0, 0, 0, .7)",
	zIndex: 1000,
};
