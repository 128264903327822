import customAxios from "../../axios";

export async function GetTransaction({ transactionId }) {
	const url = "Transaction/api/v1/GetTransaction";
	try {
		const response = await customAxios.get(url, {
			params: {
				AsyncTransactionId: transactionId,
			},
		});

		return response.data;
	} catch (error) {}
}
