import React, { useContext, useEffect, useState } from "react";
import ActivityChildReport from "../../../../Components/Planner/Reports/ActivityChildReport/ActivityChildReport";
import Sidebar from "../../../../Components/Sidebar/Sidebar";
import { Context } from "../../../../Context/Contexts";

export default function ActivityChildReportPage() {
	const { setShowFooter } = useContext(Context);
	const { setShowNavbarItems, setShowSidebarToggle } = useContext(Context);

	const [plannedDateConsult, setPlannedDateConsult] = useState(new Date());

	useEffect(() => {
		setShowFooter(false);
		setShowNavbarItems(false);
		setShowSidebarToggle(true);
	});

	return (
		<>
			<Sidebar />
			<div className="basic-page-title">Reporte de actividades</div>

			<ActivityChildReport />
		</>
	);
}
