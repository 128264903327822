import React, { useContext, useState } from "react";
import YesNoConfirm from "../../../Components/ModalConfirm/YesNoConfirm";
import { MODAL_STYLES, OVERLAY_STYLES } from "./MoveWeek_ModalStyle";
import { RESPONSIVE_MODAL_STYLES } from "../../../Components/Modal_Styles/ResponsiveModalStyle";
import * as IoIcons from "react-icons/io";
import { Context } from "../../../Context/Contexts";
import { MoveActivitiesOneWeekUp } from "../../../Components/Planner/Actions/MoveActivitiesOneWeekUp";
import { MoveActivitiesOneWeekDown } from "../../../Components/Planner/Actions/MoveActivitiesOneWeekDown";
import "./MoveWeek.css";
import { MEMBERSHIP_UPGRADE_NEEDED } from "../../../Components/Constants/Constants";

export default function MoveWeek({ weeklyScheduleDate, isOpen, closeHandler }) {
	const { setShowIsloading } = useContext(Context);
	const { setShowDonecomponent } = useContext(Context);
	const { setRefreshWeeklySchedule } = useContext(Context);
	const { setShowMembershipNeedUpgrade } = useContext(Context);

	const [showConfirmation, setShowConfirmation] = useState(false);
	const [confirmationMessage, setConfirmationMessage] = useState("");
	const [confirmationCallBack, setConfirmationCallBack] = useState();

	const MoveWeekUp = () => {
		var calendarWeek = weeklyScheduleDate;
		setShowIsloading(true);

		MoveActivitiesOneWeekUp({ calendarWeek: calendarWeek }).then((response) => {
			if (response.errors === MEMBERSHIP_UPGRADE_NEEDED) {
				setShowMembershipNeedUpgrade(true);
				setShowIsloading(false);
				setShowConfirmation(false);
				return;
			}

			setRefreshWeeklySchedule(true);
			setShowDonecomponent(true);
			setShowIsloading(false);
			closeHandler();
		});
	};
	const MoveWeekDown = () => {
		var calendarWeek = weeklyScheduleDate;
		setShowIsloading(true);

		MoveActivitiesOneWeekDown({ calendarWeek: calendarWeek }).then(
			(response) => {
				if (response.errors === MEMBERSHIP_UPGRADE_NEEDED) {
					setShowMembershipNeedUpgrade(true);
					setShowIsloading(false);
					setShowConfirmation(false);
					return;
				}

				setRefreshWeeklySchedule(true);
				setShowDonecomponent(true);
				setShowIsloading(false);
				closeHandler();
			}
		);
	};

	if (!isOpen) return null;

	return (
		<>
			<div
				style={OVERLAY_STYLES}
				onClick={() => {
					closeHandler();
				}}
			></div>
			<div
				className="move-week-container"
				style={RESPONSIVE_MODAL_STYLES(MODAL_STYLES)}
			>
				<div
					className="move-week-close-btn"
					onClick={() => {
						closeHandler();
					}}
				>
					X
				</div>
				<div className="move-week-text table-row-item">
					Puedes mover esta semana y sus actividades hacia adelante o hacia
					atrás
				</div>

				<div className="move-week-btns">
					<div
						className="do-not-print hover-brighten base-button-text move-weekly-schedule"
						onClick={() => {
							setConfirmationCallBack(() => MoveWeekDown);
							setConfirmationMessage(
								"Seguro que quieres mover estas actividades a la semana anterior?"
							);
							setShowConfirmation(true);
						}}
						title="Mover semana y actividades a la semana anterior"
					>
						<>
							<IoIcons.IoIosArrowBack /> Mover
						</>
					</div>

					<div
						className="do-not-print hover-brighten base-button-text move-weekly-schedule"
						onClick={() => {
							setConfirmationCallBack(() => MoveWeekUp);
							setConfirmationMessage(
								"Seguro que quieres mover estas actividades a la siguiente semana?"
							);
							setShowConfirmation(true);
						}}
						title="Mover semana y actividades a la siguiente semana"
					>
						<>
							<IoIcons.IoIosArrowForward /> Mover
						</>
					</div>
				</div>
			</div>

			<YesNoConfirm
				dlgOpen={showConfirmation}
				callBackFunction={() => {
					confirmationCallBack();
					setShowConfirmation(false);
				}}
				onClose={() => {
					setConfirmationCallBack(() => {});
					setShowConfirmation(false);
				}}
				confirmMessage={confirmationMessage}
			/>
		</>
	);
}
